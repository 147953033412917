import React, { useState } from 'react'
import { TailSpin } from 'react-loader-spinner'
import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import PortfolioData from '../../data/portfolio.json'



const Portfolio = () => {
  const [letterClass] = useState('text-animate')

  const renderPortfolio = (portfolio) =>{
    return (
      <div className="images-container">
        {
          portfolio.map((item,index)=>{
            return (
              <div className="image-box" key={index}>
                <img
                    src={item.cover}
                    className='portfolio-image'
                    alt='portfolio'
                />
                <div className="content">
                  <p className="title">
                    {item.title}
                  </p>
                  <h4 className="description">
                    {item.description}
                  </h4>
                  <button 
                    className="btn"
                    onClick={()=>window.open(item.url)}
                  >
                    view 
                  </button>
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }

  return (
    <>
    <div className="container portfolio-page">
        <h1 className="page-title">
          <AnimatedLetters
            letterClassName={letterClass}
            letterArray={"My Portfolio".split("")}
            letterIndex={15}
          />
        </h1>
        <div>{renderPortfolio(PortfolioData.portfolio)}</div>
    </div>
    <TailSpin
        height="50"
        width="50"
        color="#ffd700"
        ariaLabel="tail-spin-loading"
        wrapperClass="tails"
      />
    
    </>
  )
}

export default Portfolio