import { Link, NavLink } from 'react-router-dom'
import React, { useState } from 'react'
import './index.scss'
import LogoD from '../../assets/images/logo-d.png'
import LogoSubtitle from '../../assets/images/david_sub.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faClose, faEnvelope, faHome, faSuitcase, faUser } from '@fortawesome/free-solid-svg-icons'
import { faGithub, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'

const Sidebar = () => {

    const [showNav, setShowNav] = useState(false) 
  return (
    <div className="nav-bar">
        <Link className="logo" to='/'>
            <img src={LogoD} alt="" />
            <img className="sub-logo" src={LogoSubtitle} alt="David Olufuwa" />
        </Link>
        <nav className={showNav ? 'mobile-show' : ''}>
        <div className="link-holder">
            <NavLink 
                onClick={()=>setShowNav(false)}
                exact="true" 
                activeclassname = "active" 
                to="/"
            >
                <FontAwesomeIcon icon={faHome} color="#4d4d4e"/>
            </NavLink>
            <NavLink 
                onClick={()=>setShowNav(false)}
                exact="true" 
                activeclassname ="active"
                className="about-link" 
                to="/about"
            >
                <FontAwesomeIcon icon={faUser} color="#4d4d4e"/>
            </NavLink>
            <NavLink 
                onClick={()=>setShowNav(false)}
                exact="true" 
                activeclassname = "active"
                className="portfolio-link" 
                to="/portfolio"
            >
                <FontAwesomeIcon icon={faSuitcase} color="#4d4d4e"/>
            </NavLink>
            <NavLink 
                onClick={()=>setShowNav(false)}
                exact="true" 
                activeclassname = "active"
                className="contact-link" 
                to="/contact"
            >
                <FontAwesomeIcon icon={faEnvelope} color="#4d4d4e"/>
            </NavLink>
        </div>
            <FontAwesomeIcon
                onClick={()=>setShowNav(false)}
                icon={faClose}
                color="#ffd700"
                size='3x'
                className='close-icon'
            />
        </nav>
        <ul className={showNav ? 'mobile-show' : ''}>
            <li>
                <a 
                    onClick={()=>setShowNav(false)}
                    target="_blank"
                    href="https://www.linkedin.com/in/davidolufuwa"
                    rel='noreferrer'  
                >
                    <FontAwesomeIcon icon={faLinkedin} color="#4d4d4e" />
                </a>
            </li>
            <li>
                <a 
                    onClick={()=>setShowNav(false)}
                    target="_blank"
                    href="https://github.com/DavOlufuwa"
                    rel='noreferrer'  
                >
                    <FontAwesomeIcon icon={faGithub} color="#4d4d4e" />
                </a>
            </li>
            <li>
                <a 
                    onClick={()=>setShowNav(false)}
                    target="_blank"
                    href="https://twitter.com/d_lufuwa"
                    rel='noreferrer'  
                >
                    <FontAwesomeIcon icon={faTwitter} color="#4d4d4e" />
                </a>
            </li>
        </ul>
        <FontAwesomeIcon 
            onClick={()=>setShowNav(true)}
            icon={faBars}
            color="#ffd700"
            size='3x'
            className='hamburger-icon'
        />
    </div>
  )
}

export default Sidebar