import React, { useRef, useState } from 'react'
import { TailSpin } from 'react-loader-spinner'
import AnimatedLetters from '../AnimatedLetters'
import emailjs from '@emailjs/browser'
import {motion} from 'framer-motion'
import './index.scss'

const Contact = () => {
    const [letterClass] = useState('text-animate')
    const transition = {type:'spring', duration : 3}
    const [alertStatus, setAlertStatus] = useState({alertbox:"closed",errorMessage:"failure"})
    const [senderName, setSenderName] = useState('');

    const form = useRef()
    const switchOff = ()=>{
      setAlertStatus({
          ...alertStatus, 
          alertbox:"closed", 
          errorMessage:"failure"
      }); 
      document.getElementById('contact-form').reset()}
    
      const handleChange = event => {
        setSenderName(event.target.value);
      };
    
    const sendEmail = (e) => {
        e.preventDefault()
          emailjs.sendForm('service_glvbu1v', 'contact_form', form.current, 'x9jmuUxk4g244i6Wi')
          .then(
            () => {
              setAlertStatus({...alertStatus, alertbox:"opened", errorMessage:"success"})
            },
            (error) => {
              setAlertStatus({...alertStatus, alertbox:"opened",errorMessage:"failure"})
              console.log(error.text)
            }
          )
      }
    
  return (
    <>
      <div className="container contact-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClassName={letterClass}
              letterArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'm', 'e']}
              letterIndex={15}
            />
          </h1>
          <p>
            Interested in working with me on your projects, have any questions or requests, do not hesitate to contact me using form below.
          </p>
          <div className="contact-form">
            <form ref={form} onSubmit={sendEmail} id='contact-form'>
              <ul>
                <li className="half">
                  <input 
                    placeholder="Name" 
                    type="text" 
                    name="name" 
                    onChange={handleChange}
                    required 
                    />
                </li>
                <li className="half">
                  <input
                    placeholder="Email"
                    type="email"
                    name="email"
                    required
                  />
                </li>
                <li>
                  <input
                    placeholder="Subject"
                    type="text"
                    name="subject"
                    required
                  />
                </li>
                <li>
                  <textarea
                    placeholder="Message"
                    name="message"
                    required
                  ></textarea>
                </li>
                <li>
                  <input type="submit" className="flat-button" value="SEND" />
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
      {
        <div className={alertStatus.alertbox === "opened" ? "alert-container open" : "alert-container"} id="alert">
            <motion.div
                className="msg-container"
                initial ={{opacity: 0, y: -100}}
                transition = {{...transition, duration: 1}}
                whileInView = {{opacity: 1, y: 0 }}
                >
                <div className="message-box">
                    {alertStatus.errorMessage === "success" ? `Thank you ${senderName} for sending the message. I would respond to you as soon as possible`: `Oops there was an error sending your message. Please check your internet connection or try again`}
                </div>
                <button
                  className='btn'
                  onClick={switchOff} 
                >
                  okay
                </button>
            </motion.div>
        </div>
      }
    <TailSpin
        height="50"
        width="50"
        color="#ffd700"
        ariaLabel="tail-spin-loading"
        wrapperClass="tails"
    />
    </>
  )
}

export default Contact