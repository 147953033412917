import React from 'react'
import './index.scss'


const AnimatedLetters = ({letterClassName, letterArray, letterIndex}) => {
  return (
        <span>
            {
                letterArray.map((letter, i)=>(
                    <span key={letter + i} className={`${letterClassName} _${i + letterIndex}`}>
                        {letter}
                    </span>
                ))
            }
        </span>
    
    )
}

export default AnimatedLetters