import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../Logo'
import LogoTitle from '../../assets/images/logo-d.png'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'
import { TailSpin } from 'react-loader-spinner'
 

const Home = () => {
  const [letterClass] = useState('text-animate')
  const letterArray= ['a','v','i','d']
  const roleArray = ['W','e','b',' ','d','e','v','e','l','o','p','e','r', '.']



  return (
    <>
      <div className="container home-page">
          <div className="text-zone">
              <h1>
                <span className={letterClass}>H</span>
                <span className={`${letterClass} _12`}>i</span>
                <span className={`${letterClass} _12`}>,</span>
              <br />
                <span className={`${letterClass} _13`}>I</span>
                <span className={`${letterClass} _14`}>'m</span>
                <img src={LogoTitle} alt="" />
                <AnimatedLetters 
                  letterClassName={letterClass}
                  letterArray={letterArray}
                  letterIndex={15}
                  />
                <br />
                <AnimatedLetters 
                  letterClassName={letterClass}
                  letterArray={roleArray}
                  letterIndex={19}
                  />
              </h1>
              <Link to="/contact" className='flat-button'>CONTACT ME</Link>
          </div>
          <Logo />
      </div>
      <TailSpin
        height="50"
        width="50"
        color="#ffd700"
        ariaLabel="tail-spin-loading"
        wrapperClass="tails"
      />
    </>
  )
}

export default Home
// stopped at 25:02