import { faCss3, faGit, faHtml5, faJsSquare, faPython, faReact } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { TailSpin } from 'react-loader-spinner'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'


const About = () => {
    const [letterClass] = useState('text-animate')

  return (
    <>
    <div className="container about-page">
        <div className="text-zone">
            <h1>
                <AnimatedLetters 
                    letterClassName={letterClass}
                    letterArray={['A','b','o','u','t',' ','m','e']}
                    letterIndex={15}
                />
            </h1>
            <p>
                I am a goal oriented web developer looking for a role in an established IT company with the opportunity to work with latest technologies on challenging and diverse projects
            </p>
            <p>
                I have demonstrable understanding of widely used web development technologies such as <span>Html5</span>,  <span>CSS3</span>, <span>Git</span>, <span>Javascript</span>, <span>ReactJS</span>, <span>PHP</span> and <span>MYSQL</span>. 
            </p>
            <p>
                In my spare time I love playing the keyboard or drawing comics.
            </p>
        </div>
        <div className="stage-cube-cont">
            <div className="cube-spinner">
                <div className="face1">
                    <FontAwesomeIcon icon={faHtml5} color="#DD0031"/>
                </div>
                <div className="face2">
                    <FontAwesomeIcon icon={faCss3} color="#F06529"/>
                </div>
                <div className="face3">
                    <FontAwesomeIcon icon={faJsSquare} color="#28A4D9"/>
                </div>
                <div className="face4">
                    <FontAwesomeIcon icon={faReact} color="#5ED4F4"/>
                </div>
                <div className="face5">
                    <FontAwesomeIcon icon={faGit} color="#EFD81D"/>
                </div>
                <div className="face6">
                    <FontAwesomeIcon icon={faPython} color="#EC4D28"/>
                </div>
            </div>
        </div>
    </div>
    {/* <Loader type='pacman'/> */}
    <TailSpin
        height="50"
        width="50"
        color="#ffd700"
        ariaLabel="tail-spin-loading"
        wrapperClass="tails"
    />
    </>
  )
}

export default About