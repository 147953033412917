import React from 'react'
import LogoD from '../../assets/images/logo-d.png'
import './index.scss'
const Logo = () => {
  return (
    <div className="logo-container">
        <img  src={LogoD} alt="D" className="solid-logo" />
    </div>
  )
}

export default Logo